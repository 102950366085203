<template>
    <section>
        <div class="row mx-0 px-5">
            <div class="col-auto px-0 text-black f-20 f-600">
                Crear conjunto
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <ValidationObserver ref="form">
            <div class="col-6 mx-2">
                <div>
                    <div class="row mx-0">
                        <div class="col-auto px-0">
                            <p class="text-general text-center f-12">Imagen</p>
                            <img 
                            :src="conjunto.logo" 
                            class="border cr-pointer" 
                            :style="`height:155px;width:155px;background:#F8F9FF;border-radius:4px;${conjunto.logo ? conjunto.logo : 'background-image:url(/img/estados-vacios/anadir_imagen.svg);'}`" 
                            />
                            <div class="row mx-0  my-3 j-center">
                                <div class="bg-f5 border br-4 w-100 d-middle-center cr-pointer" style="height:32px;" @click="cambiarImagen">
                                    Subir Imagen
                                </div>
                            </div>
                        </div>
                        <div class="col pl-5" >
                            <p class="text-general pl-3 f-12">Nombre</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|max:50" name="nombre">
                                <el-input v-model="conjunto.nombre" placeholder="nombre" show-word-limit maxlength="50" class="w-100" size="small" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <p class="text-general pl-3 f-12 mt-2">Tipo de conjunto</p>
                            <ValidationProvider v-slot="{ errors }" rules="required" name="tipo de conjunto">
                                <el-select v-model="conjunto.tipo" placeholder="Seleccionar" class="w-100" size="small">
                                    <el-option
                                    v-for="tipo in tipos"
                                    :key="tipo.value"
                                    :label="tipo.label"
                                    :value="tipo.value">
                                    </el-option>
                                </el-select>
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div class="row mx-0">
                                <div class="col pl-0">
                                    <p class="text-general pl-3 mt-2 f-12">País</p>
                                        <select-paises @setPais="setPais" />
                                </div>
                                <div class="col pr-0">
                                    <p class="text-general pl-3 mt-2 f-12">Ciudad</p>
                                        <select-ciudades ref="selectPais" @setCiudad="setCiudad" />
                                </div>
                            </div>
                            <p class="text-general pl-3 f-12 ">Dirección</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|max:100" name="direccion">
                                <el-input v-model="conjunto.direccion" placeholder="Dirección"  show-word-limit maxlength="100" class="w-100" size="small" />
                                <span class="text-danger f-10">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div class="row mx-0">
                                <div class="col pl-0">
                                    <p class="text-general pl-3 f-12 mt-2">Latitud</p>
                                    <ValidationProvider v-slot="{ errors }" :rules="{regex: /^\d*\.?\d*$/}" name="latitud">
                                        <el-input v-model="conjunto.latitud" placeholder="latitud" class="w-100" size="small" />
                                        <span class="text-danger f-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col pr-0">
                                    <p class="text-general pl-3 f-12 mt-2">Longitud</p>
                                    <ValidationProvider v-slot="{ errors }" :rules="{regex: /^\d*\.?\d*$/}" name="longitud">
                                        <el-input v-model="conjunto.longitud" placeholder="longitud" class="w-100" size="small" />
                                        <span class="text-danger f-10">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row mx-0 mt-2">
                                <div  class="bg-general f-12 br-4 px-4 cr-pointer text-white d-middle-center" style="height:32px;" @click="guardar()">
                                <span v-loading="loading"
                                                                    element-loading-text="Cargando..."
                                                                    element-loading-background="rgba(0, 0, 0, 1)"
                                                                >
                                                                    Guardar
                                                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarLeechero" titulo="Eliminar conjunto" mensaje="¿ Desea eliminar esta vivienda ?" />
        <modal ref="modalCambiarImagen" titulo="Cambiar Imagen" no-aceptar adicional="Guardar" @adicional="setLogo">
            <div class="row mx-0 my-3 j-center">
                <div class="position-relative d-middle-center">
                    <div class="position-absolute text-center" style="z-index:2">
                        <img src="/img/estados-vacios/anadir_imagen.svg" width="80" height="80" />
                        <p class="text-center">Arrastra una imagen <br /> 
                        o haga clic aquí</p>
                    </div>
                    <slim-cropper 
                    ref="logoConjunto" 
                    :options="slimOptions" 
                    class="border cr-pointer" 
                    style="height:237px;width:237px;background:#F8F9FF;border-radius:4px;z-index:4;" 
                    />
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Conjuntos from '~/services/conjuntos'
export default {
    data(){
			return {
					loading:false,
					slimOptions: {
							ratio: '1:1',
							label:'',
							buttonCancelLabel: 'Cancelar',
							buttonConfirmLabel: 'ok',
							buttonUploadLabel: 'Ok',
					},
					tipos: [{
							value: 1,
							label: 'Apartamentos'
					}, {
							value: 2,
							label: 'Casas'
					}, {
							value: 3,
							label: 'Aptos y Casas'
					}, {
							value: 4,
							label: 'Otros'
					}],
					conjunto:{
						id:null,
						nombre:'',
						tipo:null,
						logo:null,
						id_ciudad:null,
						direccion:'',
						latitud:'',
						longitud:'',
						base_datos:'',
						dominio:'',
						bucket:'',
					},
                    id_pais:null
			}
    },
    methods: {
			eliminarLeechero(){
					this.$refs.modalEliminarLeechero.toggle();
			},
			cambiarImagen(){
					this.$refs.modalCambiarImagen.toggle();
			},
			async guardar(){
				try {

                    let validate = await this.$refs.form.validate()

                    if(!validate || !this.conjunto.id_ciudad) return this.notificacion('','Campos incompletos','warning')

					this.loading = true

					const {data} = await Conjuntos.guardar(this.conjunto)
					if(data.success){
						this.$notify({
							title: 'Éxito',
							message: data.mensaje,
							type: 'success'
						});			
					}
					this.loading = false

                    this.$router.push('/')
								
				} catch (e){
                    this.error_catch(e)
					this.loading = false
				}
			},
			setCiudad(ciudad){
				this.conjunto.id_ciudad = ciudad
			},
            setPais(pais){
				this.id_pais = pais
                this.$refs.selectPais.setIdPais(pais)
			},
			setLogo(){
				this.$refs.modalCambiarImagen.toggle()
				this.conjunto.logo = this.$refs.logoConjunto.get_image()
			}
    }
}
</script>
